@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --merriweather-font: "Merriweather", serif;
  --poppins-font: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus-visible {
  outline: 0 !important;
}

.t3-main-content .fullscreen-enabled {
  height: 100% !important;
  overflow: auto !important;
}

body {
  background: #ffffff;
  font-family: var(--poppins-font);
}

:not(:root):fullscreen::backdrop {
  background: #ffffff;
}

.t3-theme-btn {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1.429;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #ffffff;
  background: #bf212f;
  border: 1px solid #bf212f;
  border-radius: 4px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.t3-theme-btn svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.t3-theme-btn:hover {
  background: #bf212fe6;
}

.t3-theme-outline-btn {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1.429;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #bf212f;
  background: #ffffff;
  border: 1px solid #bf212f;
  border-radius: 4px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.t3-theme-outline-btn svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.t3-theme-outline-btn:hover {
  background: #bf212f;
  color: #ffffff;
}

/* login page start */
.t3-login-section {
  background: #2c2d2d;
  min-height: 100vh;
  display: flex;
}
.t3-login-section .t3-login-banner-content {
  width: 100%;
  padding: 50px 30px;
  max-height: 100vh;
}
.t3-login-section .t3-login-banner-content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 600px;
  display: block;
  margin: 0 auto;
}
.t3-login-form-content {
  background: #ffffff;
  min-height: 100vh;
  max-width: 600px;
  width: 100%;
  padding: 70px 30px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.t3-login-form-content .t3-auth-logo {
  max-width: 135px;
  display: block;
  margin: 0 auto;
}
.t3-login-form-content .t3-auth-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.t3-login-form-content .t3-login-form {
  margin: auto;
  max-width: 440px;
  width: 100%;
}
.t3-login-form-content .t3-auth-form-heading {
  font-size: 30px;
  line-height: 1.3;
  font-weight: 700;
  color: #2c2d2d;
  font-family: var(--merriweather-font);
  margin-bottom: 8px;
}
.t3-login-form-content .t3-auth-form-paragraph {
  font-size: 14px;
  line-height: 1.429;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #757575;
  margin-bottom: 30px;
}
.t3-login-form-content .t3-auth-input-content {
  margin-bottom: 16px;
}
.t3-auth-input-content .t3-auth-input-inner-content {
  position: relative;
}
.t3-auth-input-content
  .t3-auth-input-inner-content
  .t3-auth-input-content-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #757575;
  width: 22px;
  height: 22px;
  pointer-events: none;
}
.t3-auth-input-content
  .t3-auth-input-inner-content
  .t3-auth-input-content-label {
  font-size: 16px;
  line-height: 1.429;
  font-weight: 400;
  color: #757575;
  display: block;
  position: absolute;
  left: 11px;
  top: 13px;
  background: #ffffff;
  padding: 0 5px;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}
.t3-auth-input-content .t3-auth-input-inner-content .t3-auth-input {
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 12px 45px 12px 16px;
  font-size: 16px;
  line-height: 1.429;
  font-weight: 400;
  color: #111311;
  width: 100%;
  background: #ffffff;
  outline: 0;
}
.t3-auth-input-content
  .t3-auth-input-inner-content
  .t3-auth-input::placeholder {
  opacity: 0;
}
.t3-auth-input-content
  .t3-auth-input-inner-content
  .t3-auth-input:focus
  ~ label,
.t3-auth-input-content
  .t3-auth-input-inner-content
  .t3-auth-input:not(:placeholder-shown)
  ~ label {
  top: -9px;
  font-size: 14px;
}
.t3-auth-input-content .t3-auth-input-content-error {
  font-size: 14px;
  font-weight: 500;
  color: #dc3545;
  display: block;
  margin-top: 4px;
}
.t3-auth-input-content .t3-auth-input-inner-content textarea {
  font-family: var(--poppins-font);
  resize: vertical;
}
.t3-auth-input-content
  .t3-auth-input-inner-content
  textarea
  ~ .t3-auth-input-content-icon {
  top: 14.4px;
  transform: translateY(0);
}
.t3-login-form-content .t3-login-form .t3-theme-btn {
  padding: 12px 16px;
  border-radius: 6px;
}
/* login page end */

.t3-main-content {
  padding-top: 69px;
}

/* header section start */
.t3-header-section {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.t3-header-section .t3-header-content {
  background: #ffffff;
  border-bottom: 1px solid #2c2d2d1f;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}
.t3-header-section .t3-header-content .t3-header-logo {
  max-width: 135px;
  width: 100%;
  display: block;
}
.t3-header-section .t3-header-content .t3-header-logo img {
  width: 100%;
  object-fit: contain;
}
.t3-header-section .t3-header-content .t3-header-right-content {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.t3-header-section
  .t3-header-content
  .t3-header-right-content
  .t3-header-zoom-btn {
  min-width: 38px;
  width: 38px;
  height: 38px;
  background: #bf212f;
  border: 1px solid #bf212f;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}
.t3-header-section
  .t3-header-content
  .t3-header-right-content
  .t3-header-zoom-btn:hover {
  background: #bf212fe6;
}
.t3-header-section
  .t3-header-content
  .t3-header-right-content
  .t3-header-zoom-btn
  svg {
  width: 22px;
  height: 22px;
}
.t3-header-section .t3-header-content .t3-header-right-content .t3-theme-btn {
  min-width: fit-content;
}
.t3-header-section
  .t3-header-content
  .t3-header-right-content
  .t3-header-zoom-add-content-btn
  ~ .t3-theme-btn {
  margin-left: 10px;
}
/* header section end */

.t3-masonry-grid-content {
  -moz-column-count: 3;
  column-count: 3;
  -moz-column-gap: 20px;
  column-gap: 20px;
  -moz-column-fill: balance;
  column-fill: balance;
  padding: 60px 20px;
}

.t3-masonry-grid-content .t3-masonry-grid-box {
  display: block;
  margin: 0 0 20px;
  page-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid;
  width: 100%;
  background: #cfe2ff;
  border-radius: 4px;
  border: 1px solid #9ec5fe;
}
.t3-masonry-grid-content .t3-masonry-grid-box .t3-masonry-grid-box-header {
  border-bottom: 1px solid #9ec5fe;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-header-heading {
  color: #0a58ca;
  font-size: 18px;
  line-height: 1.429;
  font-weight: 600;
  text-transform: capitalize;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-close-btn {
  margin-left: auto;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 0;
  background: transparent;
  color: #0a58ca;
  cursor: pointer;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-close-btn
  svg {
  width: 25px;
  height: 25px;
}
.t3-masonry-grid-content .t3-masonry-grid-box .t3-masonry-grid-box-body {
  padding: 16px;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box
  .t3-masonry-grid-box-body
  .t3-masonry-grid-box-sch-name {
  font-size: 16px;
  line-height: 1.429;
  font-weight: 400;
  display: flex;
  margin-bottom: 10px;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box
  .t3-masonry-grid-box-body
  .t3-masonry-grid-box-sch-name
  svg {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box
  .t3-masonry-grid-box-body
  .t3-masonry-grid-box-body-paragraph {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  word-break: break-word;
}

/* masonry primary color */
.t3-masonry-grid-content .t3-masonry-grid-box.t3-masonry-grid-box-primary {
  background: #cfe2ff;
  border-color: #9ec5fe;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-primary
  .t3-masonry-grid-box-header {
  border-color: #9ec5fe;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-primary
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-header-heading,
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-primary
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-close-btn {
  color: #0a58ca;
}

/* masonry secondary color */
.t3-masonry-grid-content .t3-masonry-grid-box.t3-masonry-grid-box-secondary {
  background: #f8f9fa;
  border-color: #e9ecef;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-secondary
  .t3-masonry-grid-box-header {
  border-color: #e9ecef;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-secondary
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-header-heading,
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-secondary
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-close-btn {
  color: #6c757d;
}

/* masonry success color */
.t3-masonry-grid-content .t3-masonry-grid-box.t3-masonry-grid-box-success {
  background: #d1e7dd;
  border-color: #a3cfbb;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-success
  .t3-masonry-grid-box-header {
  border-color: #a3cfbb;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-success
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-header-heading,
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-success
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-close-btn {
  color: #146c43;
}

/* masonry danger color */
.t3-masonry-grid-content .t3-masonry-grid-box.t3-masonry-grid-box-danger {
  background: #f8d7da;
  border-color: #f1aeb5;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-danger
  .t3-masonry-grid-box-header {
  border-color: #f1aeb5;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-danger
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-header-heading,
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-danger
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-close-btn {
  color: #b02a37;
}

/* masonry warning color */
.t3-masonry-grid-content .t3-masonry-grid-box.t3-masonry-grid-box-warning {
  background: #fff3cd;
  border-color: #ffe69c;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-warning
  .t3-masonry-grid-box-header {
  border-color: #ffe69c;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-warning
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-header-heading,
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-warning
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-close-btn {
  color: #997404;
}

/* masonry info color */
.t3-masonry-grid-content .t3-masonry-grid-box.t3-masonry-grid-box-info {
  background: #cff4fc;
  border-color: #9eeaf9;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-info
  .t3-masonry-grid-box-header {
  border-color: #9eeaf9;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-info
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-header-heading,
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-info
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-close-btn {
  color: #087990;
}

/* masonry light color */
.t3-masonry-grid-content .t3-masonry-grid-box.t3-masonry-grid-box-light {
  background: #fcfcfd;
  border-color: #e9ecef;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-light
  .t3-masonry-grid-box-header {
  border-color: #e9ecef;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-light
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-header-heading,
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-light
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-close-btn {
  color: #6c757d;
}

/* masonry dark color */
.t3-masonry-grid-content .t3-masonry-grid-box.t3-masonry-grid-box-dark {
  background: #ced4da;
  border-color: #adb5bd;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-dark
  .t3-masonry-grid-box-header {
  border-color: #adb5bd;
}
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-dark
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-header-heading,
.t3-masonry-grid-content
  .t3-masonry-grid-box.t3-masonry-grid-box-dark
  .t3-masonry-grid-box-header
  .t3-masonry-grid-box-close-btn {
  color: #495057;
}

/* content modal body css start */
.t3-content-modal-bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.75);
  z-index: -9;
  width: 100%;
  height: 100%;
  background: #00000080;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}
.t3-content-modal-bg.t3-content-modal-open {
  pointer-events: all;
  opacity: 1;
  z-index: 99999;
  transform: translate(-50%, -50%) scale(1);
}
.t3-content-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.75);
  z-index: -9;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}
.t3-content-modal.t3-content-modal-open {
  pointer-events: all;
  opacity: 1;
  z-index: 999999;
  transform: translate(-50%, -50%) scale(1);
}
.t3-content-modal .t3-content-modal-content {
  max-width: 500px;
  width: auto;
  margin: 28px auto;
  min-height: calc(100% - 56px);
  display: flex;
  align-items: center;
}
.t3-content-modal .t3-content-modal-content .t3-content-modal-body {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
}
.t3-content-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-content-modal-header {
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  padding: 12px 16px;
}
.t3-content-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-content-modal-header
  h2 {
  font-size: 20px;
  font-weight: 600;
  color: #2c2d2d;
  margin: 0;
}
.t3-content-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-content-modal-header
  .t3-content-modal-close-btn {
  margin-left: auto;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 0;
  background: transparent;
  cursor: pointer;
  color: #2c2d2d;
}
.t3-content-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-content-modal-body-content {
  padding: 16px 16px;
}
.t3-content-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-content-modal-body-content
  .t3-auth-input-content {
  margin-bottom: 16px;
}
.t3-content-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-content-modal-body-content
  .t3-auth-input-content
  textarea {
  height: 155px;
  resize: none;
}
/* content modal body css end */

/* floting content css start */
.t3-floting-btn-content {
  position: fixed;
  bottom: 14px;
  right: 20px;
  z-index: 9999;
}
.t3-floting-btn-box {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #bf212f;
  border: 1px solid #bf212f;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 6px 0;
  font-size: 12px;
  font-weight: 600;
}
.t3-floting-btn-box svg {
  width: 25px;
  height: 25px;
}
.t3-floting-btn-box:hover {
  background: #c63b47;
}
/* floting content css end */

/* delete modal css start */
.t3-content-delete-modal .t3-content-modal-content .t3-content-modal-body {
  padding: 16px;
}
.t3-content-delete-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-delete-modal-img {
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto 16px;
  object-fit: contain;
}
.t3-content-delete-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-content-modal-header {
  padding: 0;
  border: 0;
}
.t3-content-delete-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-content-modal-header
  h2 {
  text-align: center;
  margin-bottom: 16px;
}
.t3-content-delete-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-modal-btn-row {
  display: flex;
  align-items: center;
  margin: 0 -5px;
}
.t3-content-delete-modal
  .t3-content-modal-content
  .t3-content-modal-body
  .t3-modal-btn-row
  > * {
  margin: 0 5px;
}
/* delete modal css end */

/* media query */

@media (max-width: 991px) {
  .t3-login-section .t3-login-banner-content {
    display: none;
  }
  .t3-login-form-content {
    max-width: 100%;
  }
  .t3-masonry-grid-content {
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 767px) {
  .t3-login-form-content .t3-auth-form-heading {
    font-size: 24px;
  }
  .t3-masonry-grid-content {
    -moz-column-count: 1;
    column-count: 1;
  }
  .t3-floting-btn-content {
    width: 100%;
    bottom: 0;
    left: 0;
    right: auto;
    display: flex;
    align-items: center;
    background: #bf212f;
  }
  .t3-floting-btn-box {
    margin: 0;
    flex: 1;
  }
  .t3-masonry-grid-content {
    padding-top: 30px;
  }
  .t3-header-section .t3-header-content .t3-header-right-content .t3-theme-btn {
    width: 38px;
    height: 38px;
    min-width: auto;
    padding: 0;
  }
  .t3-header-section
    .t3-header-content
    .t3-header-right-content
    .t3-theme-btn
    span {
    display: none;
  }
  .t3-header-section
    .t3-header-content
    .t3-header-right-content
    .t3-theme-btn
    svg {
    margin: 0;
    width: 22px;
    height: 22px;
  }
}
@media (max-width: 600px) {
  .t3-header-section
    .t3-header-content
    .t3-header-right-content
    .t3-header-zoom-add-content-btn
    ~ .t3-theme-btn {
    margin-left: 5px;
  }
  .t3-header-section .t3-header-content .t3-header-right-content .t3-theme-btn {
    width: 32px;
    height: 32px;
  }
  .t3-header-section
    .t3-header-content
    .t3-header-right-content
    .t3-header-zoom-btn {
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }
  .t3-header-section .t3-header-content {
    padding: 15px;
  }
  .t3-masonry-grid-content {
    padding: 15px 15px 60px;
  }
}
@media (max-width: 540px) {
  .t3-content-modal .t3-content-modal-content {
    max-width: calc(100% - 40px);
  }
  .t3-content-delete-modal
    .t3-content-modal-content
    .t3-content-modal-body
    .t3-modal-btn-row {
    display: block;
    margin: 0;
  }
  .t3-content-delete-modal
    .t3-content-modal-content
    .t3-content-modal-body
    .t3-modal-btn-row
    > * {
    margin: 0 0 10px;
  }
  .t3-content-delete-modal
    .t3-content-modal-content
    .t3-content-modal-body
    .t3-modal-btn-row
    > *:last-child {
    margin: 0;
  }
}
@media (max-width: 425px) {
  .t3-header-section .t3-header-content .t3-header-logo {
    max-width: 120px;
  }
}
